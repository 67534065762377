<template>
  <div class="default-user-page">

    <b-row class="m-b-sm">
      <b-col v-show="tabs[0].recordsTotal > 0" md="8">
        <!-- not empty -->
        <div class="ibox-content no-borders b-r-lg">

          <!-- title -->
          <b-row>
            <div class="article m">
              <h1>{{ $t("multiples." + label.split(".").pop() + ".other") }}</h1>
            </div>
          </b-row>

          <!-- table -->
          <b-tabs v-if="tabs.length>1" content-class="mt-3" fill>
            <b-tab :lazy="tab.recordsTotal < 0" v-for="tab in tabs" :title="$tabLabel(tab)">
              <datatable :columns="tab.columns" :fetch-records="tab.fetchFunction" :actions="tab.actions"/>
            </b-tab>
          </b-tabs>

          <datatable v-if="tabs.length===1" :columns="tabs[0].columns" :fetch-records="tabs[0].fetchFunction"
                     :actions="tabs[0].actions"/>

        </div>
      </b-col>

      <b-col v-show="tabs[0].recordsTotal >= 0" :md="tabs[0].recordsTotal<=0 ? 12 : 4">
        <div class="ibox-title">
          <h5>{{ $t("multiples." + label.split(".").pop() + ".other") }}</h5>
        </div>
        <!-- general -->
        <div class="ibox-content">
          <div>
            <p>{{ $t(label + "s.empty_label") }}</p>
            <hr>
            <b-button @click.prevent="showCreateModal" class="btn-md pull-right" variant="primary">
              {{ $t(label + "s.add") }}
            </b-button>
          </div>
        </div>
      </b-col>

    </b-row>

    <default-modal v-model="recordModalShow" :id="recordId" :title="recordModalTitle"
                   :type="recordModalType" :inputs="modalInputs" :fetch-record="fetchRecord"
                   :disabled="recordModalType==='show'"
                   :submitRecord="submitRecord"
    />

  </div>
</template>

<script>
import Datatable from "@/components/datatable";
import {sendConfirmationEmail} from "@/services/employees";
import {mapGetters} from "vuex";
import DefaultModal from "@/components/default_modal";

export default {
  name: "DefaultUserPage",
  components: {DefaultModal, Datatable},
  props: {
    label: {
      type: String
    },
    fetchRecords: {
      type: Function
    },
    fetchRecord: {
      type: Function
    },
    postRecord: {
      type: Function
    },
    putRecord: {
      type: Function
    },
    removeRecord: {
      type: Function
    },
    confirmable: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      recordId: 0,
      recordModalShow: false,
      recordModalType: '',
      recordModalTitle: '',
      tabs: [
        {
          columns: [
            {key: "id", label: "id"},
            {
              key: "full_name",
              label: "name"
            },
            {
              key: "email",
              label: "email",
            },
            {
              key: "phone_number",
              label: "phone_number",
            },
            {
              key: "confirmed",
              label: "state",
              formatter: (value, key, item) => {
                return value ? this.$t("common.active") : this.$t("dispatchers.waiting_for_confirmation")
              },
              enabled: this.confirmable
            },
            {key: "actions", label: "actions"},
          ],
          fetchFunction: async (filterQuery) => {
            const response = await this.fetchRecords(filterQuery)
            this.tabs[0].recordsTotal = response.recordsTotal || 0
            response.data.forEach((entry) => {
              entry.actions = [
                {
                  label: this.$t('button.show'),
                  action: this.showRecordDetailModal
                },
                {
                  label: this.$t('button.edit'),
                  action: this.showEditModal
                },
                {
                  label: this.$t('button.resend'),
                  action: this.sendConfirmation,
                  enabled: () => {
                    return entry.confirmed !== undefined && !entry.confirmed
                  }
                }, {
                  label: this.$t('button.remove'),
                  action: (entry) => {
                    this.$makeRemoveDialog(this).then((r) => {
                      if (r) this.removeRecord(entry.id)
                    })
                  }
                },
              ]
            })
            return response
          },
          actions: [
            {
              label: this.$t(this.label + 's.add'),
              action: this.showCreateModal
            },
          ],
          recordsTotal: -1
        }
      ],
      modalInputs: [
        {key: "first_name", label: "common.first_name", required: true},
        {key: "last_name", label: "common.last_name", required: true},
        {key: "email", label: "common.email", type: "email", required: true},
        {key: "phone_number", label: "common.phone_number", required: true},
      ]
    }
  },
  methods: {
    showCreateModal() {
      this.recordId = 0
      this.recordModalType = 'create'
      this.recordModalTitle = this.$t(this.label + 's.add')
      this.recordModalShow = true
    },
    showEditModal(entry) {
      this.recordId = entry.id
      this.recordModalType = 'edit'
      this.recordModalTitle = this.$t(this.label + 's.edit')
      this.recordModalShow = true
    },
    showRecordDetailModal(entry) {
      this.recordId = entry.id
      this.recordModalTitle = this.$t(this.label + 's.show')
      this.recordModalType = 'show'
      this.recordModalShow = true
    },
    sendConfirmation(entry) {
      sendConfirmationEmail(entry.email)
    },
    submitRecord(entry) {
      if (this.recordModalType === 'create') return this.postRecord(entry)
      else return this.putRecord(entry.id, entry)
    }
  },
  computed: {
    ...mapGetters("user", ["current_user"]),
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

</style>
